import TextInputComponent from "./TextInput";
import {formatPhone, validatePhone} from "../../Utils";

const PhoneInputComponent = (props) => {

    return (
        <TextInputComponent
            formatter={formatPhone}
            validator={validatePhone}
            {...props}
        />
    )

}

export default PhoneInputComponent;
import Base from "./Base";

export default class {
    constructor() { 
        this.instance = Base('metadata')
    }

    getCountries = () => {
        return this.instance({
            method: 'GET',
            url: `/countries`,
        });
    }

    getStates = (country) => {
        return this.instance({
            method: 'GET',
            url: `/countries/${country}/states`,
        });
    }

    getCities = (country, state) => {
        return this.instance({
            method: 'GET',
            url: `/countries/${country}/states/${state}/cities`,
        });
    }

}
const NumberInputComponent = ({
    label,
    placeholder,
    className,
    inputClassName = '',
    onChange,
    dataKey,
    value

}) => {
    return (
        <div class={`mb-3 ${className}`}>
            <label class="form-label">{label}</label>
            <input
                type="number"
                min='0'
                className={`form-control rounded-1 ${inputClassName}`}
                placeholder={placeholder}
                onChange={(e) => onChange(dataKey, e.target.value)}
                value={value[dataKey]}
            />
            <div class="form-text"></div>
        </div>
    );
}

export default NumberInputComponent;
import Base from "./Base";

export default class {
    constructor() { 
        this.instance = Base('colligates')
    }

    getColligates = () => {
        return this.instance({
            method: 'GET',
            url: ``,
        });
    }

    getBranchesAndPeriods = (colligate) => {
        return this.instance({
            method: 'GET',
            url: `/${colligate}/branches`,
        });
    }

    getOffers = (colligate, branch, period) => {
        return this.instance({
            method: 'GET',
            url: `/${colligate}/branches/${branch}/periods/${period}/offers`
        })
    }
}

const FooterComponent = () => {
    return (
        <>
            <footer class="bg-primary text-white container-fluid py-2">
                <p class="text-center text-small m-0">
                    <span class="text-nowrap fw-bold">SEG Cursos Técnicos </span>
                    <span class="text-nowrap">- Todos os direitos são reservados</span>
                </p>
            </footer>
        </>
    );
}

export default FooterComponent;
import SearchButtonComponent from "./Buttons/SearchButton";
import NumberInputComponent from "./Inputs/NumberInput";
import SelectInputComponent from "./Inputs/SelectInput";
import TextInputComponent from "./Inputs/TextInput";

import FilterIcon from "../Assets/Icons/filter.svg";
import CleanFiltersButtonComponent from "./Buttons/CleanFiltersButton";
import { useEffect, useState } from "react";

import MetadataService from "../Services/Metadata";
import { getLoginDataFromStorage, getProfiles, notifyError } from "../Utils";



const RecordSearchFormComponent = ({onSubmit, isFormCollapsed, setIsFormCollapsed, isLoading}) => {

    const metadataService = new MetadataService();

    const [statuses, setStatuses] = useState(null);
    const [isLoadingStatuses, setIsLoadingStatuses] = useState(true);
    useEffect(() => {

        setIsLoadingStatuses(true);
        metadataService.getRecordStatuses()

            .then((response) => {
                setStatuses(
                    response.data.data
                )
            })

            .catch(notifyError)

            .finally(() => {
                setIsLoadingStatuses(false);
            })
    }, [])

    const [formData, setFormData] = useState({
        "CODUSUARIO": getLoginDataFromStorage().user.CODUSUARIO,
        "CODFICHAINI": 0
    });

    useEffect(() => {
        console.log(formData);
    }, [formData])

    const clearFilters = () => {
        setFormData({});
    }

    const updateFormData = (key, value) => {
        setFormData(
            (prevState) => (
                {
                    ...prevState,
                    [key]: value
                }
            )
        )
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(formData);
    }

    const canChangeUser = getProfiles().map((i) => i == 'APPVENDA_C' || i == 'APPVENDA_S').includes(true);

    return (

        <>

            <section className="card w-100 px-3 py-2">
                <a role="button" class="w-100 text-decoration-none text-secondary fs-5" onClick={() => setIsFormCollapsed(!isFormCollapsed)}>
                    <img src={FilterIcon} className="me-1" />Filtros
                </a>

                <form className={`w-100 collapse ${!isFormCollapsed ? 'show' : ''}`} id="collapseExample" onSubmit={handleSubmit}>
                    <section className="row  mt-3">

                        <NumberInputComponent
                            label="Número Inicial"
                            className="col-6 col-md-3 "
                            placeholder="Codficha inicial"

                            value={formData}
                            onChange={updateFormData}

                            dataKey="CODFICHAINI"
                        />

                        <NumberInputComponent
                            label="Número Final"
                            className="col-6 col-md-3"
                            placeholder="Codficha final"
                            value={formData}
                            onChange={updateFormData}

                            dataKey="CODFICHAFIM"
                        />

                        <TextInputComponent
                            label="Usuário"
                            className="col-12 col-md-6 "

                            placeholder="Usuário vendedor"
                            onChange={updateFormData}
                            value={formData}
                            dataKey="CODUSUARIO"
                            disabled={!canChangeUser}
                            
                        />

                    </section>
                    <section className="row">
                        <TextInputComponent
                            label="Nome"
                            className="col-12 col-md-6"
                            placeholder="Nome do aluno"

                            value={formData}
                            onChange={updateFormData}
                            dataKey="NOME"
                        />
                        {
                            <SelectInputComponent
                                label="Status"
                                className="col-12 col-md-6"
                                optionsLabel="NOME"
                                disabled={isLoadingStatuses}
                                placeholder={isLoadingStatuses ? 'Carregando...' : 'Status da ficha'}
                                optionsKey="CODSTATUS"
                                options={statuses ? statuses : []}
                                
                                dataKey="CODSTATUS"
                                onSelect={(a, b) => {updateFormData(a, b.CODSTATUS)}}

                                
                            />
                        }

                    </section>

                    <section className="row">
                        <SearchButtonComponent className=" ms-auto col-12 col-md-2 col-lg-2" onClick={handleSubmit} isLoading={isLoading}/>
                        <CleanFiltersButtonComponent className="col-12 col-md-2 col-lg-2 " onClick={clearFilters}/>
                    </section>

                </form>

            </section>




        </>
    );
}

export default RecordSearchFormComponent;
const NextButtonComponent = ({ onClick, loading=false, disabled=false }) => {


     return (
          <>


               <button class="btn btn-primary m-1" type="submit" onClick={onClick} disabled={loading || disabled} autoFocus>
                    {loading ? <>
                         <span class="spinner-border spinner-border-sm me-1" aria-hidden="true"></span>
                         <span role="status">Aguarde...</span>
                  
                    </> : 'Avançar'}
               </button>

          </>
     );


}

export default NextButtonComponent;
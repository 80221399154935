import SearchIcon from '../../Assets/Icons/search.svg';

const SearchButtonComponent = ({ className, onClick, disabled = false, loading=false }) => {
    return (
        <>

            <div class={`mb-3 ${className}`}>

                <button class=" form-control btn btn-success m-1  text-nowrap" onClick={onClick} type="submit" disabled={loading || disabled}>
                    {loading ? <>
                        <span class="spinner-border spinner-border-sm me-1" aria-hidden="true"></span>
                        <span role="status">Aguarde...</span>

                    </> : 'Buscar'}
                </button>
            </div>





        </>
    );
}

export default SearchButtonComponent;
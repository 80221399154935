const SignInButtonComponent = ({ loading=false, disabled=false }) => {
    return (






        <button class="btn btn-primary-dark text-white form-control py-2 rounded-1" type="submit" disabled={loading || disabled}>
            {loading ? <>
                <span class="spinner-border spinner-border-sm me-1" aria-hidden="true"></span>
                <span role="status">Aguarde...</span>

            </> : 'Entrar'}
        </button>
    );
}

export default SignInButtonComponent;
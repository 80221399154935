
import NewTokenForm from "../../Components/NewTokenForm";
import PageTitle from "../../Components/PageTitle";




const NewTokenPage = () => {
    return ( 
        <>
        
            <PageTitle text="Geração de Token"/>
            
            <NewTokenForm/>

        

        </>
     );
}
 
export default NewTokenPage;
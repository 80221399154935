const PageTitle = ({text}) => {
    return ( 
        <>
        
            <h2 className="h4 text-decoration-underline fw-normal mb-4">{text}</h2>
        
        </>
     );
}
 
export default PageTitle;
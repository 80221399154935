import TextInputComponent from "../Inputs/TextInput";
import DateInputComponent from "../Inputs/DateInput";
import NextButtonComponent from "../Buttons/NextButton";
import PreviousButtonComponent from "../Buttons/PreviousButton";
import SelectInputComponent from "../Inputs/SelectInput";
import PhoneInputComponent from "../Inputs/PhoneInput";
import CpfInputComponent from "../Inputs/CpfInput";

import EmailInputComponent from "../Inputs/EmailInput";
import IdentityInputComponent from "../Inputs/IdentityInput";
import { useEffect, useState } from "react";
import MetadataService from "../../Services/Metadata";
import LocationService from "../../Services/Location";
import RecordService from "../../Services/Record";
import CepInputComponent from "../Inputs/CepInput";
import { cleanCep, cleanCpf, cleanIdentity, formatCep, formatCpf, notifyError } from "../../Utils";

const StudentDataForm = ({ previousStep, nextStep, codRecord }) => {
    const recordService = new RecordService();
    const metadataService = new MetadataService();
    const locationService = new LocationService();

    const [formData, setFormData] = useState({});
    const [isLoadingRecord, setIsLoadingRecord] = useState(true);
    const [isStepValid, setIsStepValid] = useState(false);
    const [genders, setGenders] = useState({ loading: false, data: null });
    const [maritalStatuses, setMaritalStatuses] = useState({ loading: false, data: null });
    const [originState, setOriginState] = useState({ loading: false, data: null });
    const [originCity, setOriginCity] = useState({ loading: false, data: null })

    const updateFormData = (key, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [key]: value,
        }));
    };

    useEffect(() => {
        setIsLoadingRecord(true);
        recordService.getRecordById(codRecord)
            .then((response) => setFormData(response.data.data))
            .finally(() => setIsLoadingRecord(false));
    }, [codRecord]);

    useEffect(() => {
        setGenders({ loading: true });
        metadataService.getGenders()
            .then((response) => setGenders({ loading: false, data: response.data.data }))
            .catch((error) => {
                notifyError(error);
                setGenders({ loading: false, data: null })}
            );

        setMaritalStatuses({ loading: true });
        metadataService.getMaritalStatuses()
            .then((response) => setMaritalStatuses({ loading: false, data: response.data.data }))
            .catch((error) => {
                setMaritalStatuses({ loading: false, data: null });
                notifyError(error);
            
            });

        setOriginState((prevState) => ({ ...prevState, data: null, loading: true }));
        locationService.getStates(1)
            .then((response) => setOriginState({ loading: false, data: response.data.data }))
            .catch((error) => {
                notifyError(error)
                setOriginState({ loading: false, data: null })}
            );
    }, []);

    useEffect(() => {
        if (formData.RF_ESTADONATAL) {
            setOriginCity((prevState) => ({ ...prevState, loading: true }));
            locationService.getCities(formData.PAIS, formData.RF_ESTADONATAL)
                .then((response) => setOriginCity({ loading: false, data: response.data.data }))
                .catch(notifyError)
                .catch(() => setOriginCity({ loading: false, data: null }));
        }
    }, [formData.RF_ESTADONATAL]);

    useEffect(() => {
        console.log({ formData });
    }, [formData])

    const handleSubmit = () => {
        setIsLoadingRecord(true);
        recordService.updateRecord(codRecord, formData)
            .then(() => nextStep())
            .catch(notifyError)
            .finally(() => setIsLoadingRecord(false));
    };

    useEffect(() => {
        setIsStepValid(
            formData.ESTADONATAL && formData.RF_NOME && formData.NATURALIDADE && formData.DTNASCIMENTO
        )
    }, [formData])

    return (
        <form className="d-flex flex-column flex-grow-1" onSubmit={(e) => e.preventDefault()}>
            <section className="row">
                <h6>Dados do Responsável Financeiro</h6>

                <TextInputComponent
                    label="Nome"
                    placeholder={isLoadingRecord ? "Carregando..." : ""}
                    className="col-12 col-md-4"
                    value={formData.RF_NOME || ""}
                    dataKey="RF_NOME"
                    onChange={updateFormData}
                    disabled={isLoadingRecord}
                />

                <PhoneInputComponent
                    label="Telefone Celular"
                    placeholder={isLoadingRecord ? "Carregando..." : ""}
                    className="col-12 col-md-4"
                    value={formData.RF_FONECEL || ""}
                    dataKey="RF_FONECEL"
                    onChange={updateFormData}
                    disabled={isLoadingRecord}
                />

                <CpfInputComponent
                    label="CPF"
                    placeholder={isLoadingRecord ? "Carregando..." : ""}
                    className="col-12 col-md-4"
                    value={formatCpf(formData.RF_CPF || "")}
                    dataKey="RF_CPF"
                    onChange={(key, value) => updateFormData(key, cleanCpf(value))}
                    disabled={isLoadingRecord}
                />

                <DateInputComponent
                    label="Data de Nascimento"
                    placeholder={isLoadingRecord ? "Carregando..." : ""}
                    className="col-12 col-md-4"
                    value={formData.RF_DTNASCIMENTO || ""}
                    dataKey="RF_DTNASCIMENTO"
                    onChange={updateFormData}
                    disabled={isLoadingRecord}
                />

                <CepInputComponent
                    label="CEP"
                    placeholder={isLoadingRecord ? "Carregando..." : ""}
                    className="col-12 col-md-4"
                    value={formatCep(formData.RF_CEP || "")}
                    dataKey="RF_CEP"
                    onChange={(key, value) => updateFormData(key, cleanCep(value))}
                    disabled={isLoadingRecord}
                />

                <EmailInputComponent
                    label="Email"
                    placeholder={isLoadingRecord ? "Carregando..." : ""}
                    className="col-12 col-md-4"
                    value={formData.RF_EMAIL || ""}
                    dataKey="RF_EMAIL"
                    onChange={updateFormData}
                    disabled={isLoadingRecord}
                />

                <SelectInputComponent
                    label="Sexo"
                    placeholder={genders.loading || isLoadingRecord ? "Carregando..." : "Sexo"}
                    options={genders.data && genders.data.map((item) => ({ key: item.CODINTERNO, label: item.NOME }))}
                    value={formData.RF_SEXO || ""}
                    onSelect={(option) => updateFormData("RF_SEXO", option.key)}
                    disabled={!genders.data || genders.loading || isLoadingRecord}
                    className="col-12 col-md-4"
                />

                <SelectInputComponent
                    label="Estado Civil"
                    placeholder={maritalStatuses.loading || isLoadingRecord ? "Carregando..." : "Estado Civil"}
                    options={maritalStatuses.data && maritalStatuses.data.map((item) => ({ key: item.CODINTERNO, label: item.NOME }))}
                    value={formData.RF_ESTADOCIVIL || ""}
                    onSelect={(option) => updateFormData("RF_ESTADOCIVIL", option.key)}
                    disabled={!maritalStatuses.data || maritalStatuses.loading || isLoadingRecord}
                    className="col-12 col-md-4"
                />

                <SelectInputComponent
                    label="Estado Natal"
                    placeholder={(originState.loading || isLoadingRecord) ? "Carregando..." : "Estado Natal"}
                    options={originState.data && originState.data.map((item) => ({ ...item, key: item['CODESTADO'], label: item['CODESTADO'] }))}
                    value={formData.RF_ESTADONATAL || ""}
                    onSelect={(option) => {

                        if (formData.RF_ESTADONATAL != option.CODESTADO) {
                            updateFormData("RF_NATURALIDADE", null);
                        }

                        updateFormData("RF_ESTADONATAL", option.CODESTADO);

                    }}
                    disabled={!originState.data || originState.loading || isLoadingRecord}
                    className="col-12 col-md-4"
                />

                <SelectInputComponent
                    label="Cidade Natal"
                    placeholder={(originCity.loading || originState.loading || isLoadingRecord) ? "Carregando..." : "Cidade Natal"}
                    options={originCity.data && originCity.data.map((item) => ({ ...item, key: item['NOME'], label: item['NOME'] }))}
                    value={formData.RF_NATURALIDADE || ""}
                    onSelect={(option) => {
                        updateFormData("RF_NATURALIDADE", option.NOME);
                    }}
                    disabled={!originCity.data || originState.loading || isLoadingRecord}
                    className="col-12 col-md-4"
                />

                <TextInputComponent
                    label="Nome do Pai"
                    placeholder={isLoadingRecord ? "Carregando..." : ""}
                    className="col-12 col-md-4"
                    value={formData.RF_NOMEPAI || ""}
                    dataKey="RF_NOMEPAI"
                    onChange={updateFormData}
                    disabled={isLoadingRecord}
                />

                <TextInputComponent
                    label="Nome da Mãe"
                    placeholder={isLoadingRecord ? "Carregando..." : ""}
                    className="col-12 col-md-4"
                    value={formData.RF_NOMEMAE || ""}
                    dataKey="RF_NOMEMAE"
                    onChange={updateFormData}
                    disabled={isLoadingRecord}
                />

            </section>

            <section className="row mt-3">
                <h6>Documentos do Responsável Financeiro</h6>

                <IdentityInputComponent
                    label="RG"
                    placeholder={isLoadingRecord ? "Carregando..." : ""}
                    className="col-12 col-md-4"
                    dataKey="RF_CIDENTIDADE"
                    value={formData.RF_CIDENTIDADE || ""}
                    onChange={(key, value) => updateFormData(key, cleanIdentity(value))}
                    disabled={isLoadingRecord}
                />

                <TextInputComponent
                    label="Órgão Expeditor"
                    placeholder={isLoadingRecord ? "Carregando..." : ""}
                    className="col-8 col-md-3"
                    dataKey="RF_CI_ORGAO"
                    value={formData.RF_CI_ORGAO || ""}
                    onChange={updateFormData}
                    disabled={isLoadingRecord}
                />

                <SelectInputComponent
                    label="Estado"
                    placeholder={(originState.loading || isLoadingRecord) ? "Carregando..." : "UF"}
                    options={originState.data && originState.data.map((item) => ({ ...item, key: item['CODESTADO'], label: item['CODESTADO'] }))}
                    value={formData.RF_CI_UF || ""}
                    onSelect={(option) => {
                        updateFormData("RF_CI_UF", option.CODESTADO);
                    }}
                    disabled={!originState.data || originState.loading || isLoadingRecord}
                    className="col-4 col-md-1"
                />
            </section>

            <div className="flex-grow-1 d-flex justify-content-end align-items-end">
                <PreviousButtonComponent onClick={previousStep} />
                <NextButtonComponent onClick={handleSubmit} loading={isLoadingRecord} />
            </div>
        </form>
    );
};

export default StudentDataForm;

import Base from "./Base";

export default class {
    constructor() { 
        this.instance = Base('/auth')
    }

    login = (data) => {
        return this.instance({
            method: 'POST',
            url: `/login`,
            data
        });
    }

    getProfiles = () => {
        return this.instance({
            method: 'GET',
            url: `/profiles`,
        });
    }
}
import Base from "./Base";

export default class {
    constructor() { 
        this.instance = Base('tokens')
    }

    createToken = (data) => {
        return this.instance({
            method: 'POST',
            url:'',
            data
        })
    }

   

 

}
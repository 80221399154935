import TextInputComponent from "./TextInput";
import {formatIdentity, validateIdentity} from "../../Utils";

const IdentityInputComponent = (props) => {

    return (
        <TextInputComponent
            formatter={formatIdentity}
            validator={validateIdentity}
            {...props}
        />
    )

}

export default IdentityInputComponent;
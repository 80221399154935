import Base from "./Base";

export default class {
    constructor() {
        this.instance = Base('system')
    }

    getSystemUsage = () => {
        return this.instance({
            method: 'GET',
            url: '/usage',
        });
    }

    cleanCache = () => {
        return this.instance({
            method: 'GET',
            url: '/cleanCache',
        });
    }
}

import {jwtDecode} from "jwt-decode";

const STORAGE_KEY = "data-key";
const INFO_KEY = "steps-key";

export const saveDataInStorage = data => {
  try {
    sessionStorage.setItem(STORAGE_KEY, JSON.stringify(data));
    return true;
  } catch (error) {
    return false;
  }
};

export const getLoginDataFromStorage = () => {
  try {
    const data = JSON.parse(sessionStorage.getItem(STORAGE_KEY));
    return {
      ...data,
      errorMessage: null
    };
  } catch (err) {
    return {
      errorMessage:
        "Falha na autenticação, verifique seus dados e tente novamente"
    };
  }
};

export const getStepsInformation = () => {
  try {
    const data = JSON.parse(sessionStorage.getItem(INFO_KEY));
    return data || {};
  } catch {
    return {
      error: true
    };
  }
};

export const updateStepsInformation = newData => {
  try {
    const oldData = JSON.parse(sessionStorage.getItem(INFO_KEY) || '{}');
    sessionStorage.setItem(
      INFO_KEY,
      JSON.stringify({
        ...oldData,
        ...newData
      })
    );
    return true;
  } catch (error) {
    return false;
  }
};

export const clearStepsInformation = () => {
  try {
    sessionStorage.removeItem(INFO_KEY);
    return true;
  } catch {
    return false;
  }
};

export const logoffUserAndEraseData = () => {
  try {
    sessionStorage.removeItem(STORAGE_KEY);
    if (!clearStepsInformation()) {
      throw new Error();
    }
    return true;
  } catch {
    return false;
  }
};

export const isLogged = () => {
  try {
    const loginData = JSON.parse(sessionStorage.getItem(STORAGE_KEY));
    if (loginData.token) {
      const token = jwtDecode(loginData.token);
      const date = new Date().getTime() / 1000;
      if (token.exp < date) {
        logoffUserAndEraseData();
        return false;
      }
      return true;
    }
    return false;
  } catch {
    return false;
  }
};

export const getProfiles = () => {
  try {
    const { user } = JSON.parse(sessionStorage.getItem(STORAGE_KEY));
    return user.PERFIS || [];
  } catch {
    return [];
  }
};


import Utils from "../Utils";

const TableComponent = ({ data, fields }) => {

    return (
        <>
            <div class="table-responsive" style={{ overflowX: "auto" }}>
                <table class="table table-striped ">
                    <thead>
                        <tr>
                            {
                                fields.map((field) => <th scope="col">{field.title}</th>)
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.map(
                                (row) => <>
                                    <tr>

                                        {
                                            fields.map(
                                                (field) =>
                                                    <td className="text-nowrap">
                                                        {
                                                            field.component ? field.component(row) : field.format ? field.format(row[field.key]) : row[field.key]
                                                        }
                                                    </td>
                                            )
                                        }



                                    </tr>
                                </>

                            )
                        }

                    </tbody>
                </table>
            </div>
        </>
    );
}

export default TableComponent;
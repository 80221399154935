import TextInputComponent from "./TextInput";
import {validateEmail} from "../../Utils";

const EmailInputComponent = (props) => {

    return (
        <TextInputComponent
            validator={validateEmail}
            {...props}
        />
    )

}

export default EmailInputComponent;
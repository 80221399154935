
import { useEffect, useState } from "react";


import SystemService from "../Services/System";
import PageTitle from "../Components/PageTitle";
import { notifyError } from "../Utils";

const AdminPage = () => {

    const systemService = new SystemService();

    const [systemUsage, setSystemUsage] = useState({
        loading: false,
        data: null
    });

    useEffect(() => {

        setSystemUsage((prevState) => ({ ...prevState, loading: true }));

        systemService.getSystemUsage()
            .then((response) => {
                setSystemUsage((prevState) => ({ ...prevState, data: response.data.data }));
            })
            .catch(notifyError)
            .finally(() => {
                setSystemUsage((prevState) => ({ ...prevState, loading: false }));

            })

    }, [])

    return (
        <>

            <PageTitle text="Administração" />

            {
                (systemUsage.loading || !systemUsage.data) ? <>

                    <section className="row d-none d-md-flex" >

                        <div className="text-white px-2 py-1 m-1 rounded-1" style={{ width: '140px', backgroundColor: '#D3D3D3' }}>
                            <p className="mb-0 fw-bold fs-3 invisible ">100%</p>
                            <p className="mb-0 invisible ">Uso CPU</p>
                        </div>

                        <div className="text-white px-2 py-1 m-1 rounded-1" style={{ width: '170px', backgroundColor: '#D3D3D3' }}>
                            <p className="mb-0 fw-bold fs-3 invisible " >100%</p>
                            <p className="mb-0 invisible ">Uso RAM</p>
                        </div>
                    </section>

                    <section className="row d-flex d-md-none g-2 mt-2">

                        <div className="col-6">
                            <div className="text-white px-2 py-1 rounded-1-2 " style={{ backgroundColor: '#D3D3D3' }}>
                                <p className="mb-0 fw-bold fs-3 invisible " >100%</p>
                                <p className="mb-0 invisible ">Uso CPU</p>
                            </div>
                        </div>

                        <div className="col-6">
                            <div className="text-white px-2 py-1 rounded-1-2  " style={{ backgroundColor: '#D3D3D3' }}>
                                <p className="mb-0 fw-bold fs-3 invisible " >100%</p>
                                <p className="mb-0 invisible ">Uso RAM</p>
                            </div>
                        </div>
                    </section>

                </> : <>
                    <section className="row d-none d-md-flex" >

                        <div className="text-white px-2 py-1 m-1 rounded-1" style={{ width: '140px', backgroundColor: '#183b71' }}>
                            <p className="mb-0 fw-bold fs-3" >{systemUsage.data.cpu}</p>
                            <p className="mb-0">Uso CPU</p>
                        </div>

                        <div className="text-white px-2 py-1 m-1 rounded-1" style={{ width: '170px', backgroundColor: '#009462' }}>
                            <p className="mb-0 fw-bold fs-3" >{systemUsage.data.ram}</p>
                            <p className="mb-0">Uso RAM</p>
                        </div>
                    </section>

                    <section className="row d-flex d-md-none g-2 mt-2">

                        <div className="col-6">
                            <div className="text-white px-2 py-1 rounded-1-2 " style={{ backgroundColor: '#183b71' }}>
                                <p className="mb-0 fw-bold fs-3" >{systemUsage.data.cpu}</p>
                                <p className="mb-0">Uso CPU</p>
                            </div>
                        </div>

                        <div className="col-6">
                            <div className="text-white px-2 py-1 rounded-1-2  " style={{ backgroundColor: '#009462' }}>
                                <p className="mb-0 fw-bold fs-3" >{systemUsage.data.ram}</p>
                                <p className="mb-0">Uso RAM</p>
                            </div>
                        </div>
                    </section>
                </>
            }

            <section className="mt-3">
                <p className="fs-5">Ações</p>

                <button className="btn btn-warning" onClick={
                    () => {
                        systemService.cleanCache()
                            .then((response) => {
                                if (response.data.data === true) {
                                    alert('Cache limpo com sucesso');
                                } else {
                                    alert('Erro ao limpar cache');
                                }
                            })
                            .catch((error) => {
                                notifyError(error);
                                alert('Erro ao limpar cache');
                            })
                    }
                }>
                    Limpar Cache
                </button>

            </section>




        </>
    );
}

export default AdminPage;
import FooterComponent from "../Components/Footer";
import HeaderComponent from "../Components/Header";

import Logo from '../Assets/logo.png';
import TextInputComponent from "../Components/Inputs/TextInput";
import PasswordInputComponent from "../Components/Inputs/PasswordInput";

import SignInButtonComponent from "../Components/Buttons/SignInButton";
import AuthService from "../Services/Auth";
import BannerService from "../Services/Banner";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { notifyError, saveDataInStorage } from "../Utils";

const LoginPage = () => {

    const [formData, setFormData] = useState({
        user: '',
        password: ''
    });

    const navigate = useNavigate();

    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const [banner, setBanner] = useState(null);

    useEffect(() => {

        const bannerService = new BannerService();

        bannerService.getBanner()

            .then((response) => {
                setBanner(response.data.data[0]);
            })

            .catch(notifyError)

            .finally(() => {

            })

    }, [])

    const updateFormData = (key, value) => {
        setFormData(
            (prevState) => (
                {
                    ...prevState,
                    [key]: value
                }
            )
        )
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        const authService = new AuthService();

        setIsLoading(true);

        authService.login(
            formData
        )

            .then((response) => {
                navigate("/");

                saveDataInStorage(response.data);
            })

            .catch((error) => {
                setError(error.response.data.message || 'Tente novamente mais tarde');
                notifyError(error);
            })

            .finally(() => {
                setIsLoading(false);
            })



    }

    return (
        <>

            <div className="login-page-container d-flex flex-column" style={{overflowY: 'hidden'}}>

                <HeaderComponent />


                <section className="flex-grow-1 h-100 d-flex">

                    <aside className="col bg-white d-flex flex-column align-items-center">

                        <img src={Logo} className="img-fluid mt-5 mb-5" width="200px" />
                        <form className="col-8" onSubmit={handleSubmit}>
                            <TextInputComponent
                                label="Usuário"
                                placeholder="Digite seu usuário"

                                dataKey="user"
                                onChange={updateFormData}
                                value={formData} />


                            <PasswordInputComponent
                                label="Senha"
                                placeholder="Digite sua senha"
                                dataKey="password"
                                onChange={updateFormData}
                                value={formData}
                            />

                            <SignInButtonComponent loading={isLoading} disabled={!formData['user'] || !formData['password']} />
                        </form>

                        <p className="text-danger">{error}</p>

                    </aside>

                    <aside className="col-0 col-md-8 bg-light d-none d-md-block" style={{height: '300px'}}>
                        <img
                            src={
                                banner ? banner.IMAGE : ''
                            }
                            
                            className="img-fluid "
                        />
                    </aside>

                </section>

                <FooterComponent />

            </div>
        </>
    );
}

export default LoginPage;
import { Outlet, useNavigate } from "react-router-dom";
import FooterComponent from "../Components/Footer";
import HeaderComponent from "../Components/Header";
import SidebarComponent from "../Components/Sidebar";

import { getLoginDataFromStorage } from "../Utils";
import { useEffect, useState } from "react";

const Layout = () => {
    const navigate = useNavigate();
    const [isLoadingInfos, setIsLoadingInfos] = useState(true);

    useEffect(() => {
        setIsLoadingInfos(true);
        if (!getLoginDataFromStorage().user) {
            setIsLoadingInfos(false);
            navigate('/entrar');
        }
        setIsLoadingInfos(false);
    }, []);

    return (
        <div className="d-flex flex-column" style={{ height: '100vh' }}>
            <HeaderComponent />
            <section className="flex-grow-1 d-flex" style={{ overflowY: 'hidden' }}>
                <SidebarComponent />
                <main className="px-5 py-4 flex-grow-1 d-flex flex-column w-100" style={{ overflowY: 'auto' }}>
                    {isLoadingInfos ? '' : <Outlet />}
                </main>
            </section>
            <FooterComponent />
        </div>
    );
}

export default Layout;

const GenericButtonComponent = ({ className="btn-primary", title="", type="submit", onClick, loading=false, disabled=false }) => {
    return (
         <>
            <button class={`btn m-1 ${className}`} type={type} onClick={onClick} disabled={loading || disabled} autoFocus>
                {loading ? <>
                    <span class="spinner-border spinner-border-sm me-1" aria-hidden="true"></span>
                    <span role="status">Aguarde...</span>
                
                </> : title}
            </button>

         </>
    );


}

export default GenericButtonComponent;
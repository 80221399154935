import { useState, useEffect } from "react";

import NextButtonComponent from "../Buttons/NextButton";
import TextInputComponent from "../Inputs/TextInput";
import PreviousButtonComponent from "../Buttons/PreviousButton";
import RecordService from "../../Services/Record";
import LocationService from "../../Services/Location";
import MetadataService from "../../Services/Metadata";
import SelectInputComponent from "../Inputs/SelectInput";
import OfferService from "../../Services/Offer";
import { formatMoney, notifyError } from "../../Utils";
import GenericButtonComponent from "../Buttons/GenericButton";

const Step5 = ({ previousStep, nextStep, codRecord, setCodRecord }) => {

    const recordService = new RecordService();
    const locationService = new LocationService();
    const metadataService = new MetadataService();
    const offerService = new OfferService();

    const [isLoadingRecord, setIsLoadingRecord] = useState(true);

    const [formData, setFormData] = useState({});
    const [isSaving, setIsSaving] = useState(false);

    const [paymentMethods, setPaymentMethods] = useState({
        loading: false,
        data: null
    });

    const [offerPrice, setOfferPrice] = useState({
        loading: false,
        data: null
    });

    useEffect(() => {
        console.log({ offerPrice })
    }, [offerPrice])

    useEffect(() => {

        setPaymentMethods((prevState) => ({ ...prevState, loading: true }));
        metadataService.getPaymentMethods()

            .then((response) => {
                setPaymentMethods((prevState) => ({ ...prevState, data: response.data.data }));
            })

            .catch(notifyError)


            .finally(() => {
                setPaymentMethods((prevState) => ({ ...prevState, loading: false }));
            });
    }, [])

    useEffect(() => {

        if (formData.CODCOLIGADA && formData.IDHABILITACAOFILIAL) {
            setOfferPrice((prevState) => ({ ...prevState, loading: true }));

            const offerId = `${formData.CODCOLIGADA}| |${formData.IDHABILITACAOFILIAL}`;
            offerService.getOfferPrice(offerId)

                .then((response) => {
                    setOfferPrice((prevState) => ({ ...prevState, data: response.data.data }));
                })

                .catch(notifyError)


                .finally(() => {
                    setOfferPrice((prevState) => ({ ...prevState, loading: false }));
                });
        }

    }, [formData.CODCOLIGADA, formData.IDHABILITACAOFILIAL])

    const updateFormData = (key, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [key]: value,
        }));
    };


    useEffect(() => {
        setIsLoadingRecord(true);
        recordService.getRecordById(codRecord)
            .then((response) => setFormData(response.data.data))
            .catch(notifyError)
            .finally(() => setIsLoadingRecord(false));
    }, [codRecord]);


    const handleSubmit = () => {
        setIsSaving(true);
        recordService.updateRecord(codRecord, formData)
            .then(() => nextStep())
            .catch(notifyError)
            .finally(() => setIsSaving(false));
    };

    return (
        <>

            <form className="d-flex flex-column flex-grow-1" onSubmit={(e) => { e.preventDefault(); }}>
                <section className="row">

                    <h6>Pagamento</h6>
                    <SelectInputComponent
                        label="Forma de Pagamento"
                        placeholder={(paymentMethods.loading || isLoadingRecord) ? "Carregando..." : "Forma de Pagamento"}
                        options={paymentMethods.data && paymentMethods.data.map((item) => ({ ...item, key: item['FORMAPAG'], label: item['DESCRICAO'] + " - " + item['TIPODOC'] }))}
                        value={formData.FORMAPAG || ""}
                        onSelect={(option) => {
                            updateFormData("FORMAPAG", option.FORMAPAG);
                            updateFormData("TAXA_REAJUSTE", option.ACRESCIMO)
                        }}
                        disabled={!paymentMethods.data || paymentMethods.loading || isLoadingRecord}
                        className="col-12 col-md-4"
                    />

                    <TextInputComponent
                        label="Entrada"
                        placeholder=""
                        className="col-12 col-md-4"
                    />
                    <TextInputComponent
                        label="Taxa Reajuste"
                        placeholder=""
                        disabled={true}
                        className="col-12 col-md-4"
                        value={formData.TAXA_REAJUSTE ? formatMoney(formData.TAXA_REAJUSTE) : 'Selecione uma forma de pagamento'}
                    />
                    <TextInputComponent
                        label="Preço"
                        placeholder=""
                        className="col-12 col-md-4"
                        disabled={true}
                        value={formatMoney(offerPrice.data ? offerPrice.data.PRECO : '')}
                    />

                    <TextInputComponent
                        label="Data Entrada"
                        placeholder=""
                        className="col-12 col-md-4"
                    />

                    <TextInputComponent
                        label="Data Inicio do Reajuste"
                        placeholder=""
                        className="col-12 col-md-4"
                    />

                    <TextInputComponent
                        label="Desconto"
                        placeholder=""
                        className="col-12 col-md-4"
                    />

                    <TextInputComponent
                        label="Preço com Desconto"
                        placeholder=""
                        className="col-12 col-md-4"
                    />

                    <TextInputComponent
                        label="Data Vencimento"
                        placeholder=""
                        className="col-12 col-md-4"
                    />

                    <TextInputComponent
                        label="Número do Cheque Entregue"
                        placeholder=""
                        className="col-12 col-md-4"
                    />

                </section>



                <div className="flex-grow-1 d-flex justify-content-end  align-items-end" >
                    <PreviousButtonComponent onClick={previousStep} />
                    <GenericButtonComponent
                        onClick={nextStep}

                        title="Simular parcelamento"
                        className="btn-success"
                        
                    />
                </div>
            </form>

        </>
    );
}

export default Step5;
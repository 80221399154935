import { useEffect, useState } from "react";
import TableComponent from "../Components/Table";
import RecordService from "../Services/Record";
import { formatCpf, getLoginDataFromStorage, notifyError, updateStepsInformation } from "../Utils";
import { useNavigate, createSearchParams } from "react-router-dom";

import EditIcon from "../Assets/Icons/edit.svg";

const HomePage = () => {

    const navigate = useNavigate();

    const fields = [
        {
            "key": "CODFICHA",
            "title": "ID"
        },
        {
            "key": "CPF",
            "title": "CPF",
            'format': formatCpf
        },
        {
            "key": "NOME",
            "title": "Nome"
        },
        {
            "key": "CODFILIAL",
            "title": "Filial"
        },
        {
            "key": "NOMECURSOCURTO",
            "title": "Curso",
            "format": (nome) => {
                return nome.split(" - ")[0];
            }
        },
        {
            "key": "RECMODIFIEDON",
            "title": "Ultima Alteração",
            "format": (dataISO) => {
                const data = new Date(dataISO);
                const dia = String(data.getDate()).padStart(2, '0');
                const mes = String(data.getMonth() + 1).padStart(2, '0'); // Meses são 0-11
                const ano = data.getFullYear();
                const horas = String(data.getHours()).padStart(2, '0');
                const minutos = String(data.getMinutes()).padStart(2, '0');

                return `${dia}/${mes}/${ano} ${horas}:${minutos}`;
            }
        },
        {
            "title": "Editar",
            "component": (row) => (
                <>
                    <button
                        type="button"
                        className="btn btn-sm btn-primary"
                        onClick={
                            () => navigate({
                                pathname: "/ficha",
                                search: createSearchParams({
                                    id:row.CODFICHA
                                }).toString()
                            })
                        }
                    >
                        <img src={EditIcon} />
                    </button>
                </>
            )
        }
    ]

    const [pendingRecords, setPendingRecords] = useState([]);

    const recordService = new RecordService();

    useEffect(() => {
        recordService.getMyPendingRecords()

            .catch(notifyError)

            .then((response) => {
                setPendingRecords(response.data.data);
            })

    }, []);

    return (
        <>
            <p className="h3">Olá, {getLoginDataFromStorage().user.NOME}!</p>

            <section className="row d-none d-md-flex" >

                <div className="text-white px-2 py-1 m-1 rounded-1" style={{ width: '140px', backgroundColor: '#183b71' }}>
                    <p className="mb-0 fw-bold fs-3" >12</p>
                    <p className="mb-0">Total</p>
                </div>

                <div className="text-white px-2 py-1 m-1 rounded-1" style={{ width: '170px', backgroundColor: '#009462' }}>
                    <p className="mb-0 fw-bold fs-3" >10</p>
                    <p className="mb-0">Confirmadas</p>
                </div>

                <div className="text-white px-2 py-1 m-1 rounded-1" style={{ width: '160px', backgroundColor: '#f58634' }}>
                    <p className="mb-0 fw-bold fs-3" >2</p>
                    <p className="mb-0">Pendentes</p>
                </div>

                <div className="text-white px-2 py-1 m-1 rounded-1" style={{ width: '170px', backgroundColor: '#008dcf' }}>
                    <p className="mb-0 fw-bold fs-3" >R$ 300,56</p>
                    <p className="mb-0">Ticket</p>
                </div>

            </section>

            <section className="row d-flex d-md-none g-2 mt-2">

                <div className="col-6 ">
                    <div className="text-white px-2 py-1 rounded-1-2 " style={{ backgroundColor: '#183b71' }}>
                        <p className="mb-0 fw-bold fs-3" >12</p>
                        <p className="mb-0">Vendas</p>
                    </div>
                </div>

                <div className="col-6 ">
                    <div className="text-white px-2 py-1 rounded-1-2  " style={{ backgroundColor: '#009462' }}>
                        <p className="mb-0 fw-bold fs-3" >10</p>
                        <p className="mb-0">Confirmadas</p>
                    </div>
                </div>

                <div className="col-6">
                    <div className="text-white px-2 py-1 rounded-1-2 " style={{ backgroundColor: '#f58634' }}>
                        <p className="mb-0 fw-bold fs-3" >2</p>
                        <p className="mb-0">Pendentes</p>
                    </div>
                </div>

                <div className="col-6 ">
                    <div className="text-white px-2 py-1 rounded-1-2 " style={{ backgroundColor: '#008dcf' }}>
                        <p className="mb-0 fw-bold fs-3" >R$ 300,56</p>
                        <p className="mb-0">Ticket</p>
                    </div>
                </div>


            </section>

            {
                pendingRecords.length > 0 ? <section className="mt-3">
                    <p className="fs-5 mb-0">Suas Fichas Pendentes</p>

                    <TableComponent data={pendingRecords} fields={fields} />
                </section> : ''
            }

        </>
    );
}

export default HomePage;
import SearchIcon from '../../Assets/Icons/search.svg';


const CleanFiltersButtonComponent = ({className, onClick}) => {
    return (
        <>


            <div class={`mb-3 ${className}`}>

                <button onClick={onClick} type='reset' className="form-control rounded-1 btn btn-warning text-nowrap" >
                    Limpar Filtros
                </button>
                <div class="form-text"></div>
            </div>




        </>
    );
}

export default CleanFiltersButtonComponent;
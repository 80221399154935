const ProgressBarComponent = ({ className, total, done }) => {

    const progress = done/total * 100;

    console.log({done, total})
    return (
        <>
    

                {/* {
                    steps.map((step, index) => {
                        return <>

        
                                <div class={step.finished ? 'steps-finish' : 'steps-white'}>
                                    <span class="font-weight-bold">{step.title}</span>
                                </div>

                                {
                                    (index + 1 != steps.length)
                                        ? <span class={step.finished ? 'line-finish' : 'line-white'}></span>
                                        : ""
                                }
                    

                        </>
                    })
                } */}

                <div class="progress w-100">
                    <div class="progress-bar bg-success" role="progressbar" style={{width:  `${progress}%`}} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100">
                        {done}/{total}
                    </div>
                </div>

       
        </>
    );
}

export default ProgressBarComponent;
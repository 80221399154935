import Base from "./Base";

export default class {
    constructor() { 
        this.instance = Base('logs')

    }

    saveError = (data) => {
        return this.instance({
            method: 'POST',
            url:'/error',
            data
        })
    }

}
import { useState, useEffect } from "react";
import TextInputComponent from "../Inputs/TextInput";
import PreviousButtonComponent from "../Buttons/PreviousButton";
import NextButtonComponent from "../Buttons/NextButton";
import PhoneInputComponent from "../Inputs/PhoneInput";
import CepInputComponent from "../Inputs/CepInput";
import RecordService from "../../Services/Record";
import LocationService from "../../Services/Location";
import SelectInputComponent from "../Inputs/SelectInput";
import { notifyError } from "../../Utils";

const StepContactInfo = ({ previousStep, nextStep, codRecord }) => {

    const recordService = new RecordService();
    const locationService = new LocationService();

    const [formData, setFormData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isLoadingRecord, setIsLoadingRecord] = useState(true);
    const [residenceCity, setResidenceCity] = useState({ loading: false, data: null });
    const [residenceState, setResidenceState] = useState({ loading: false, data: null });


    useEffect(() => {
        setResidenceState((prevState) => ({ ...prevState, data: null, loading: true }));
        locationService.getStates(1)
            .then((response) => setResidenceState({ loading: false, data: response.data.data }))
            .catch((error) => {
                setResidenceState({ loading: false, data: null })
                notifyError(error);
            });
    }, []);

    useEffect(() => {
        if (formData.ESTADO) {
            setResidenceCity((prevState) => ({ ...prevState, loading: true }));
            locationService.getCities('1', formData.ESTADO)
                .then((response) => setResidenceCity({ loading: false, data: response.data.data }))
                .catch((error) => {
                    setResidenceCity({ loading: false, data: null })
                    notifyError(error);
                });
        }
    }, [formData.ESTADO]);

    const updateFormData = (key, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [key]: value,
        }));
    };

    useEffect(() => {
        setIsLoadingRecord(true);
        recordService.getRecordById(codRecord)
            .then((response) => setFormData(response.data.data))
            .catch(notifyError)
            .finally(() => setIsLoadingRecord(false));
    }, [codRecord]);

    const handleSubmit = () => {
        setIsSaving(true);
        recordService.updateRecord(codRecord, formData)
            .then(() => nextStep())
            .catch(notifyError)
            .finally(() => setIsSaving(false));
    };

    useEffect(() => {
        console.log({ formData });
    }, [formData]);

    return (
        <form className="d-flex flex-column flex-grow-1" onSubmit={(e) => { e.preventDefault(); }}>
            <section className="row">
                <h6>Endereço</h6>


                <CepInputComponent
                    label="CEP"
                    placeholder={isLoadingRecord ? "Carregando..." : ""}
                    className="col-12 col-md-4"
                    dataKey="CEP"
                    value={formData.CEP || ""}
                    onChange={updateFormData}
                    disabled={isLoadingRecord}

                />

                <TextInputComponent
                    label="Rua"
                    placeholder={isLoadingRecord ? "Carregando..." : ""}
                    className="col-12 col-md-8"
                    dataKey="RUA"
                    value={formData.RUA || ""}
                    onChange={updateFormData}
                    disabled={isLoadingRecord}
                />

                <TextInputComponent
                    label="Número"
                    placeholder={isLoadingRecord ? "Carregando..." : ""}
                    className="col-12 col-md-2"
                    dataKey="NUMERO"
                    value={formData.NUMERO || ""}
                    onChange={updateFormData}
                    disabled={isLoadingRecord}
                />

                <TextInputComponent
                    label="Complemento"
                    placeholder={isLoadingRecord ? "Carregando..." : ""}
                    className="col-12 col-md-4"
                    dataKey="COMPLEMENTO"
                    value={formData.COMPLEMENTO || ""}
                    onChange={updateFormData}
                    disabled={isLoadingRecord}
                />

                <TextInputComponent
                    label="Bairro"
                    placeholder={isLoadingRecord ? "Carregando..." : ""}
                    className="col-12 col-md-6"
                    dataKey="BAIRRO"
                    value={formData.BAIRRO || ""}
                    onChange={updateFormData}
                    disabled={isLoadingRecord}
                />

                <SelectInputComponent
                    label="Cidade"
                    placeholder={(residenceCity.loading || residenceState.loading || isLoadingRecord) ? "Carregando..." : "Cidade"}
                    options={residenceCity.data && residenceCity.data.map((item) => ({ ...item, key: item['NOME'], label: item['NOME'] }))}
                    value={formData.CIDADE || ""}
                    onSelect={(option) => {
                        updateFormData("CIDADE", option.NOME);
                    }}
                    disabled={!residenceCity.data || residenceState.loading || isLoadingRecord}
                    className="col-12 col-md-4"
                />

                <SelectInputComponent
                    label="Estado"
                    placeholder={(residenceState.loading || isLoadingRecord) ? "Carregando..." : "Estado"}
                    options={residenceState.data && residenceState.data.map((item) => ({ ...item, key: item['CODESTADO'], label: item['CODESTADO'] }))}
                    value={formData.ESTADO || ""}
                    onSelect={(option) => {

                        if (formData.ESTADO != option.CODESTADO) {
                            updateFormData("ESTADO", option.CODESTADO);
                            updateFormData("CIDADE", null);
                        }

                    }}
                    disabled={!residenceState.data || residenceState.loading || isLoadingRecord}
                    className="col-12 col-md-2"
                />
            </section>

            <section className="row mt-3">
                <h6>Contatos</h6>

                <PhoneInputComponent
                    label="Celular"
                    placeholder={isLoadingRecord ? "Carregando..." : ""}
                    className="col-12 col-md-4"
                    dataKey="TELEFONE1"
                    value={formData.TELEFONE1 || ""}
                    onChange={updateFormData}
                    disabled={isLoadingRecord}
                />

                <PhoneInputComponent
                    label="Telefone Residencial"
                    placeholder={isLoadingRecord ? "Carregando..." : ""}
                    className="col-12 col-md-4"
                    dataKey="TELEFONE2"
                    value={formData.TELEFONE2 || ""}
                    onChange={updateFormData}
                    disabled={isLoadingRecord}
                />

                <PhoneInputComponent
                    label="Telefone Comercial"
                    placeholder={isLoadingRecord ? "Carregando..." : ""}
                    className="col-12 col-md-4"
                    dataKey="TELEFONE3"
                    value={formData.TELEFONE3 || ""}
                    onChange={updateFormData}
                    disabled={isLoadingRecord}
                />

                <TextInputComponent
                    label="Email"
                    placeholder={isLoadingRecord ? "Carregando..." : ""}
                    className="col-12 col-md-6"
                    dataKey="EMAIL"
                    value={formData.EMAIL || ""}
                    onChange={updateFormData}
                    disabled={isLoadingRecord}
                />
            </section>

            <div className="flex-grow-1 d-flex justify-content-end align-items-end">
                <PreviousButtonComponent onClick={previousStep} />
                <NextButtonComponent onClick={handleSubmit} loading={isSaving} />
            </div>
        </form>
    );
};

export default StepContactInfo;

const CheckboxInputComponent = ({label, onChange}) => {
    return (
        <div class="mb-3 form-check">
            <input type="checkbox" class="form-check-input" onChange={(e) => onChange(e.target.checked)}/>
            <label class="form-check-label">{label}</label>
        </div>

    );
}

export default CheckboxInputComponent;
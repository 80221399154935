import axios from 'axios';

import { getLoginDataFromStorage } from '../Utils'

const api = (baseURL = '') => {
  const instance = axios.create({
    baseURL: `https://api.portaldevendas.lbhm.com.br/${baseURL}`,
    headers: {
      'Authorization': `Bearer ${getLoginDataFromStorage().token}`,
    },
  })
  return instance
}

export default api;


import Base from "./Base";

export default class {
    constructor() {
        this.instance = Base('banner')
    }

    getBanner = () => {
        return this.instance({
            method: 'GET',
            url: ``,
        });
    }
}

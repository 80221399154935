import TextInputComponent from "./TextInput";
import {formatCep, validateCep} from "../../Utils";

const CepInputComponent = (props) => {

    return (
        <TextInputComponent
            formatter={formatCep}
            validator={validateCep}
            {...props}
        />
    )

}

export default CepInputComponent;
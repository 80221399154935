import Base from "./Base";

export default class {
    constructor() { 
        this.instance = Base('records')
    }

    searchRecords = (data) => {
        return this.instance({
            method: 'POST',
            url:'/search',
            data
        })
    }


    getMyPendingRecords = (user) => {
        return this.instance({
            method: 'GET',
            url: `/pending`
        });
    }

    initializeRecord(cpf, email) {
        return this.instance({
            method: 'POST',
            url: '',
            data: {
                cpf, email
            }
        })
    }

    updateRecord(id, data) {
        return this.instance({
            method: 'PUT',
            url: `/${id}`,
            data
        })
    }

    getRecordById(id) {
        return this.instance({
            method: 'GET',
            url: `/${id}`
        })
    }

   

 

}
import Base from "./Base";

export default class {
    constructor() { 
        this.instance = Base('offers')
    }

    getOfferPrice = (offerId) => {
        return this.instance({
            method: 'GET',
            url: `/${offerId}/price`,
        });
    }

}

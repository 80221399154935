import { useEffect } from "react";

const SelectInputComponent = ({
    label,
    placeholder = 'Selecione uma opção',
    className = '',
    options = [], // Define um valor padrão como array vazio
    optionsKey = 'key',
    optionsLabel = 'label',
    onSelect,
    value = null,
    disabled = false,
  }) => {
    const handleChange = (event) => {
      const selectedKey = event.target.value;
      const selectedOption = options.find(option => option[optionsKey] === selectedKey);
      if (onSelect && selectedOption) {
        onSelect(selectedOption); // Passa o objeto inteiro
      }
    };

  
    return (
      <div className={`mb-3 ${className}`}>
        {label && <label className="form-label">{label}</label>}
        <select
          className="form-control rounded-1"
          value={value || ''}
          onChange={handleChange}
          disabled={disabled}
        >
          {/* Renderiza o placeholder se não houver valor selecionado */}
          <option value="" disabled>
            {placeholder}
          </option>
          {/* Mapeia as opções para renderizar no select */}
          {options && options.map((option) => (
            <option key={option[optionsKey]} value={option[optionsKey]}>
              {option[optionsLabel]}
            </option>
          ))}
        </select>
      </div>
    );
  };
  
  export default SelectInputComponent;
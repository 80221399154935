import Logo from "../Assets/logo.png";
import MainMenuModel from "../Models/MainMenu";

import HomeIcon from "../Assets/Icons/Sidebar/home.svg";

const SidebarComponent = () => {
    return (
        <>
            <div class="flex-shrink-0 p-3 bg-white d-none d-md-block " style={{ width: "250px" }}>
                <a href="/" class="d-flex align-items-center pb-3 mb-3 link-dark text-decoration-none border-bottom">
                    <img src={Logo} className="img-fluid p-4" />
                </a>
                <ul class="list-unstyled ps-0">

                    <li class="mb-1">

                        <div>
                            <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">

                                <li ><a href="/" class="link-dark rounded-1"> <img src={HomeIcon} width="15px" className="me-1" /> Início</a></li>


                            </ul>
                        </div>
                    </li>

                    {
                        MainMenuModel().map((menuGroup, index) => <>

                            {
                                !menuGroup.enabled ? '' :
                                    <>


                                        <li class="mb-1" key={index}>
                                            <button class="btn btn-toggle align-items-center rounded-1 collapsed" data-bs-toggle="collapse" data-bs-target={`#${menuGroup.title}-collapse`} aria-expanded="true">
                                                {menuGroup.title}
                                            </button>
                                            <div class="collapse show" id={`${menuGroup.title}-collapse`}>
                                                <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                                                    {
                                                        menuGroup.options.map((menuGroupOption, indexMenuGroupOption) => menuGroupOption.enabled ? <li key={indexMenuGroupOption}><a href={menuGroupOption.url} class="link-dark rounded-1"> <img src={menuGroupOption.icon} width="15px" className="me-1" /> {menuGroupOption.title}</a></li> : ''
                                                        )
                                                    }
                                                </ul>
                                            </div>
                                        </li>


                                    </>
                            }


                        </>)
                    }


                </ul>
            </div>
        </>
    );
}

export default SidebarComponent;
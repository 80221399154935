import AddIcon from '../../Assets/Icons/add.svg';

const GenerateButtonComponent = ({ onClick, loading = false, disabled = false }) => {


    return (
        <>

            <button onClick={onClick} disabled={disabled || loading} className="form-control rounded-1 btn btn-success text-nowrap">
                {loading ? <>
                    <span class="spinner-border spinner-border-sm me-1" aria-hidden="true"></span>
                    <span role="status">Aguarde...</span>

                </> : <>


                    <img src={AddIcon} alt="Add" />
                    Gerar

                </>}
            </button>

        </>
    );


}

export default GenerateButtonComponent;
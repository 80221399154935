import TextInputComponent from "./TextInput";
import {formatCpf, validateCpf} from "../../Utils";

const CpfInputComponent = (props) => {

    return (
        <TextInputComponent
            formatter={formatCpf}
            validator={validateCpf}
            {...props}
        />
    )

}

export default CpfInputComponent;

import MainMenuModel from "../Models/MainMenu";
import { getLoginDataFromStorage } from "../Utils";

const HeaderComponent = () => {
    return (
        <>
            <nav class="navbar navbar-expand-md bg-primary shadow navbar-dark">
                <div class="container-fluid flex-md-row-reverse">
                    <h1 class="navbar-brand text-white fs-4 m-0 p-0">Portal de Vendas - SEG</h1>
                    {
                        !getLoginDataFromStorage().user ? '' : <>

                            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                <span class="navbar-toggler-icon"></span>
                            </button>

                            <div class="collapse navbar-collapse " id="navbarNavDropdown">
                                <ul class="navbar-nav d-md-none">

                                    <li class="nav-item" >
                                        <a class="nav-link" aria-current="page" href="/">Início</a>
                                    </li>

                                    {
                                        MainMenuModel().map((menuGroup, menuGroupIndex) => menuGroup.enabled ? <>

                                            <p className="text-white fw-bold mb-0 mt-3" key={menuGroupIndex}>{menuGroup.title}</p>

                                            {menuGroup.options.map((menuGroupOption, menuGroupOptionIndex) => menuGroup.enabled ? <>


                                                <li class="nav-item" key={menuGroupOptionIndex}>
                                                    <a class="nav-link" aria-current="page" href={menuGroupOption.url}>{menuGroupOption.title}</a>
                                                </li>

                                            </> : ''
                                            )}


                                        </> : ''
                                        )
                                    }


                                </ul>
                            </div>


                        </>
                    }

                </div>
            </nav>
        </>
    );
}



export default HeaderComponent;
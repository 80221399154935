import { useEffect, useState } from "react";

import PageTitle from "../../Components/PageTitle";
import RecordFormStep1 from "../../Components/RecordForm/Step1";
import NewRecordSteps from "../../Models/NewRecordSteps";
import ProgressBarComponent from "../../Components/Progress";
import { getStepsInformation, scrollToTop, updateStepsInformation } from "../../Utils";

import {useLocation} from 'react-router-dom';

const NewRecordPage = () => {

    const [currentStep, setCurrentStep] = useState(0);

    const [CurrentStepElement, setCurrentStepElement] = useState(() => RecordFormStep1);

    useEffect(() => {
        setCurrentStepElement(() => NewRecordSteps[currentStep].element);        
        scrollToTop();
    }, [currentStep]);

    const nextStep = () => {
        if (currentStep + 1 == NewRecordSteps.length) {
            window.location.href = '/'
            return;
        }
        
        setCurrentStep(
            currentStep + 1
        );        
    }

    const previousStep = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    };

    const [codRecord, setCodRecord] = useState(null);

    const query = new URLSearchParams(useLocation().search);

    useEffect(() => {


        if (query.get("id")) {
            setCodRecord(query.get("id"));
            setCurrentStep(1);
        }

    }, [])

   

    return (
        <>
            <section className="d-flex justify-content-between align-items-center flex-column flex-md-row mb-3 mb-md-0">
                <PageTitle text="Cadastro de Ficha" />
                <div className="col-12 col-md-4">
                    <ProgressBarComponent total={NewRecordSteps.length} done={currentStep} />
                </div>
            </section>

            <CurrentStepElement nextStep={nextStep} previousStep={previousStep} codRecord={codRecord} setCodRecord={setCodRecord} />
        </>
    );
}

export default NewRecordPage;
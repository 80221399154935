import SearchButtonComponent from "./Buttons/SearchButton";
import NumberInputComponent from "./Inputs/NumberInput";
import SelectInputComponent from "./Inputs/SelectInput";
import TextInputComponent from "./Inputs/TextInput";
import CpfInputComponent from "./Inputs/CpfInput";

import { useEffect, useState } from "react";
import MetadataService from "../Services/Metadata";
import DateInputComponent from "./Inputs/DateInput";

import ColligatesService from "../Services/Colligates";
import GenerateButtonComponent from "./Buttons/GenerateButton";
import TokenService from "../Services/Token";
import { cleanCpf, formatCep, formatCpf, notifyError } from "../Utils";

const NewTokenForm = ({ onSubmit }) => {

    const metadataService = new MetadataService();
    const colligatesService = new ColligatesService();
    const tokenService = new TokenService();

    const [formData, setFormData] = useState({});
    const [isSaving, setIsSaving] = useState(false);

    const [tokenTypes, setTokenTypes] = useState([]);
    const [isLoadingTokenTypes, setIsLoadingTokenTypes] = useState(true);

    const [shifts, setShifts] = useState([]);
    const [branchesAndPeriods, setBranchesAndPeriods] = useState({
        loading: false,
        data: null
    });

    const [colligates, setColligates] = useState({
        loading: false,
        data: null
    });

    const [offers, setOffers] = useState({
        loading: false,
        data: null
    });

    useEffect(() => {
        console.log({ formData });
    }, [formData]);

    useEffect(() => {
        if (formData.CODCOLIGADA && formData.CODFILIAL && formData.IDPERLET && offers.data) {
            const filteredShifts = offers.data.filter((offer) => offer.CODCURSO === formData.CODCURSO);
            setShifts(filteredShifts);
        } else {
            setShifts([]);
        }
    }, [formData.CODCOLIGADA, formData.CODFILIAL, formData.IDPERLET]);

    useEffect(() => {
        setIsLoadingTokenTypes(true);
        metadataService.getTokenTypes()
            .then((response) => {
                setTokenTypes(response.data.data);
            })
            .catch(notifyError)
            .finally(() => {
                setIsLoadingTokenTypes(false);
            });

        setColligates((prevData) => ({ ...prevData, loading: true }));
        colligatesService.getColligates()
            .then((response) => {
                setColligates((prevData) => ({ ...prevData, data: response.data.data }));
            })
            .catch(notifyError)
            .finally(() => {
                setColligates((prevData) => ({ ...prevData, loading: false }));
            });
    }, []);

    useEffect(() => {
        if (formData.CODCOLIGADA) {
            setBranchesAndPeriods((prevData) => ({ ...prevData, data: null }));
            setBranchesAndPeriods((prevData) => ({ ...prevData, loading: true }));
            colligatesService.getBranchesAndPeriods(formData.CODCOLIGADA)
                .then((response) => setBranchesAndPeriods((prevData) => ({ ...prevData, data: response.data.data })))
                .finally(() => setBranchesAndPeriods((prevData) => ({ ...prevData, loading: false })));
        }
    }, [formData.CODCOLIGADA]);

    useEffect(() => {
        if (formData.CODCOLIGADA && formData.CODFILIAL && formData.IDPERLET) {
            setOffers((prevData) => ({ ...prevData, data: null }));
            setOffers((prevData) => ({ ...prevData, loading: true }));
            colligatesService.getOffers(formData.CODCOLIGADA, formData.CODFILIAL, formData.IDPERLET)
                .then((response) => setOffers((prevData) => ({ ...prevData, data: response.data.data })))
                .finally(() => setOffers((prevData) => ({ ...prevData, loading: false })));
        }
    }, [formData.CODCOLIGADA, formData.CODFILIAL, formData.IDPERLET]);

    const handleSubmit = () => {
        setIsSaving(true);
        tokenService.createToken(formData)
            .then((response) => console.log(response.data))
            .finally(() => setIsSaving(false));
    };

    return (
        <form className="w-100">

            <section className="row">
                <SelectInputComponent
                    label="Tipo"
                    className="col-12 col-md-3"
                    optionsKey="CODTIPOTOKEN"
                    optionsLabel="NOME"
                    options={tokenTypes}
                    placeholder={isLoadingTokenTypes ? 'Carregando...' : 'Tipo de Token'}
                    disabled={isLoadingTokenTypes}
                    value={formData.TIPOTOKEN || ''}
                    onSelect={(selectedOption) => setFormData((prevData) => ({ ...prevData, TIPOTOKEN: selectedOption.CODTIPOTOKEN }))}
                />
                <NumberInputComponent
                    label="Valor"
                    className="col-12 col-md-3"
                    dataKey="VALOR"
                    value={formData.VALOR || ''}
                    onChange={(key, value) => setFormData((prevData) => ({ ...prevData, [key]: value }))}
                />

                <CpfInputComponent
                    label="CPF Alvo"
                    className="col-12 col-md-3"
                    dataKey="CPF"
                    value={formatCpf(formData.CPF || '') }
                    onChange={(key, value) => setFormData((prevData) => ({ ...prevData, [key]: cleanCpf(value) }))}
                />

                <TextInputComponent
                    label="CODFICHA Alvo"
                    className="col-12 col-md-3"
                    dataKey="CODFICHA"
                    value={formData.CODFICHA || ''}
                    onChange={(key, value) => setFormData((prevData) => ({ ...prevData, [key]: value }))}
                />

            </section>

            <section className="row">
                <SelectInputComponent
                    label="Coligada"
                    placeholder={colligates.loading ? "Carregando..." : colligates.data ? "Selecione uma Coligada" : "Sem dados"}
                    disabled={!colligates.data}
                    className="col-12 col-md-6"
                    optionsLabel="NOME"
                    optionsKey="CODCOLIGADA"
                    options={colligates.data}
                    value={formData.CODCOLIGADA || ''}
                    onSelect={(selectedOption) => setFormData((prevData) => ({ ...prevData, CODCOLIGADA: selectedOption.CODCOLIGADA, IDPERLET: null, CODCURSO: null }))}
                />

                <SelectInputComponent
                    label="Filial / Período Letivo"
                    placeholder={branchesAndPeriods.loading ? 'Carregando' : branchesAndPeriods.data ? 'Selecione uma Filial/Periodo' : 'Sem dados'}
                    className="col-12 col-md-6"
                    disabled={!formData.CODCOLIGADA || !branchesAndPeriods.data || branchesAndPeriods.loading}
                    optionsLabel="NOME"
                    optionsKey="IDPERLET"
                    options={branchesAndPeriods.data}
                    value={formData.IDPERLET || ''}
                    onSelect={(selectedOption) => setFormData((prevData) => ({ ...prevData, CODFILIAL: selectedOption.CODFILIAL, IDPERLET: selectedOption.IDPERLET, CODCURSO: null }))}
                />

                <SelectInputComponent
                    label="Curso"
                    placeholder={offers.loading ? "Carregando..." : offers.data ? "Selecione um Curso" : "Sem dados"}
                    options={offers.data ? Object.values(offers.data.reduce((acc, item) => {
                        if (!acc[item.CODCURSO]) acc[item.CODCURSO] = item;
                        return acc;
                    }, {})) : []}
                    optionsKey="CODCURSO"
                    optionsLabel="NOMECURSO"
                    disabled={!offers.data}
                    className="col-12 col-md-6"
                    value={formData.CODCURSO || ''}
                    onSelect={(selectedOption) => setFormData((prevData) => ({ ...prevData, CODCURSO: selectedOption.CODCURSO, CODTURNO: null }))}
                />

                <SelectInputComponent
                    label="Turno"
                    placeholder={offers.loading ? 'Carregando...' : offers.data ? 'Selecione um Turno' : 'Sem dados'}
                    options={(formData.CODCURSO && offers.data) ? Object.values(offers.data.reduce((acc, item) => {
                        if (!acc[item.CODTURNO] && item.CODCURSO === formData.CODCURSO) acc[item.CODTURNO] = item;
                        return acc;
                    }, {})) : []}
                    optionsKey="CODTURNO"
                    optionsLabel="NOMETURNO"
                    disabled={!offers.data || !formData.CODCURSO}
                    className="col-12 col-md-6"
                    value={formData.CODTURNO || ''}
                    onSelect={(selectedOption) => setFormData((prevData) => ({ ...prevData, CODTURNO: selectedOption.CODTURNO }))}
                />
            </section>

            <section className="row">


                <DateInputComponent
                    label="Data Inicio"
                    className="col-6"
                    dataKey="DATAINICIAL"
                    value={formData.DATAINICIAL || ''}
                    onChange={(key, value) => setFormData((prevData) => ({ ...prevData, [key]: value }))}
                />

                <DateInputComponent
                    label="Data Fim"
                    className="col-6"
                    dataKey="DATAFINAL"
                    value={formData.DATAFINAL || ''}
                    onChange={(key, value) => setFormData((prevData) => ({ ...prevData, [key]: value }))}
                />


            </section>

            <section className="row">
                <TextInputComponent
                    label="Descrição"
                    className="col-12 col-md-6"
                    dataKey="DESCRICAO"
                    value={formData.DESCRICAO || ''}
                    onChange={(key, value) => setFormData((prevData) => ({ ...prevData, [key]: value }))}
                />
            </section>

            <section className="row">


                <div className="ms-auto mb-3 col-12 col-md-2">
                   
                   <GenerateButtonComponent
                    loading={isSaving}
                    onClick={handleSubmit}

                   
                   />
                    <div className="form-text"></div>
                </div>
            </section>
        </form>
    );
};

export default NewTokenForm;

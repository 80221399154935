import LogService from "../Services/Log";
import { getLoginDataFromStorage, updateStepsInformation } from "./storage";
import { useNavigate } from 'react-router-dom';

export * from "./formatation";
export * from "./storage";
export * from "./validation";


export const scrollToTop = () => {
    window.scrollTo(0, 0);
}

  
export const getUniqueObjects = (arr) => {
    return arr.filter((obj, index, self) =>
        index === self.findIndex((o) => (
            JSON.stringify(o) === JSON.stringify(obj)
        ))
    );
}

export const notifyError = (error) => {
    const logService = new LogService();

    logService.saveError(
        {
            error,
            user: getLoginDataFromStorage()
        }
    )
}
import Base from "./Base";

export default class {
    constructor() { 
        this.instance = Base('metadata')
    }
  
    getMaritalStatuses = () => {
        return this.instance({
            method: 'GET',
            url: `/maritalStatuses`,
        });
    }

    getPerson = (key) => {
        return this.instance({
            method: 'GET',
            url: `/customer/${key}`
        });
    }

    getGenders = () => {
        return this.instance({
            method: 'GET',
            url: `/genders`,
        });
    }

    getBanner = () => {
        return this.instance({
            method: 'GET',
            url: `/banner`,
        });
    }

    getNationalities = () => {
        return this.instance({
            method: 'GET',
            url: `/nationalities`
        })
    }

    getRecordStatuses() {
        return this.instance({
            method: 'GET',
            url: '/recordStatuses'
        })
    }

    getTokenTypes() {
        return this.instance({
            method: 'GET',
            url: '/tokenTypes'
        })
    }

    getPaymentMethods = () => {
        return this.instance({
            method: 'GET',
            url: `/paymentMethods`,
        });
    }

}
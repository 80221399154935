import { useEffect, useState } from "react";

const DateInputComponent = ({
    label,
    placeholder,
    className='',
    formatter,
    value,
    onChange,
    validator,
    dataKey,
    disabled = false

}) => {

    const [error, setError] = useState(null);

    const [isValid, setIsValid] = useState(null);

    const handleChange = (e) => {

        if (onChange) {
            onChange(
                dataKey, formatter ? formatter(e.target.value + "T00:00:00") : e.target.value
            );
        }

        if (validator) {
            const isValid = validator(e.target.value)

            if (isValid === false) {
                setError("Valor inválido");
                setIsValid(null);
            } else if (isValid === true) {
                setError(null)
                setIsValid(true);
            } else {
                setError(null);
                setIsValid(null);
            }
        }

    }


    return (
        <div class={`mb-3 ${className}`}>
            <label class="form-label">{label}</label>


            <input
                type="date"
                disabled={disabled}
                className={`form-control rounded-1 ${error ? "is-invalid" : isValid ? "is-valid" : ""}`}
                placeholder={placeholder}
                onChange={handleChange}


                value={(value && typeof value == 'object') ? (value[dataKey] || '').split("T")[0] : typeof value == 'string' ? (value || '').split("T")[0] : ''}

            />







            {error ? <div class="form-text text-danger">{error}</div> : ""}
        </div>
    );
}

export default DateInputComponent;
import CheckboxInputComponent from "../Inputs/CheckboxInput";
import NextButtonComponent from "../Buttons/NextButton";
import TextInputComponent from "../Inputs/TextInput";
import PreviousButtonComponent from "../Buttons/PreviousButton";
import { useEffect, useState } from "react";
import SelectInputComponent from "../Inputs/SelectInput";
import MetadataService from "../../Services/Metadata";
import LocationService from "../../Services/Location";
import RecordService from "../../Services/Record";
import DateInputComponent from "../Inputs/DateInput";
import IdentityInputComponent from "../Inputs/IdentityInput";
import { cleanIdentity, notifyError } from "../../Utils";

const Step3 = ({ previousStep, nextStep, codRecord }) => {

    const metadataService = new MetadataService();
    const locationService = new LocationService();
    const recordService = new RecordService();

    const [formData, setFormData] = useState({});
    const [isStepValid, setIsStepValid] = useState(false);
    const [isLoadingRecord, setIsLoadingRecord] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [genders, setGenders] = useState({ loading: false, data: null });
    const [maritalStatuses, setMaritalStatuses] = useState({ loading: false, data: null });
    const [originCountry, setOriginCountry] = useState({ loading: false, data: null });
    const [originState, setOriginState] = useState({ loading: false, data: null });
    const [originCity, setOriginCity] = useState({ loading: false, data: null });
    const [nationalities, setNationalities] = useState({ loading: false, data: null });

    const updateFormData = (key, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [key]: value,
        }));
    };

    useEffect(() => {
        setIsStepValid(
            formData.ESTADONATAL && formData.NOME && formData.NATURALIDADE && formData.DTNASCIMENTO
        )
    }, [formData])

    useEffect(() => {
        setIsLoadingRecord(true);
        recordService.getRecordById(codRecord)
            .then((response) => setFormData(response.data.data))
            .catch(notifyError)
            .finally(() => setIsLoadingRecord(false));
    }, [codRecord]);

    useEffect(() => {
        setGenders((prevState) => ({ ...prevState, loading: true }));
        metadataService.getGenders()
            .then((response) => setGenders({ loading: false, data: response.data.data }))
            .catch(notifyError)
            .catch(() => setGenders({ loading: false, data: null }));

        setMaritalStatuses((prevState) => ({ ...prevState, loading: true }));
        metadataService.getMaritalStatuses()
            .then((response) => setMaritalStatuses({ loading: false, data: response.data.data }))
            .catch(notifyError)
            .catch(() => setMaritalStatuses({ loading: false, data: null }));

        setOriginCountry((prevState) => ({ ...prevState, loading: true }));
        locationService.getCountries()
            .then((response) => setOriginCountry({ loading: false, data: response.data.data }))
            .catch(notifyError)
            .catch(() => setOriginCountry({ loading: false, data: null }));

        setNationalities((prevState) => ({ ...prevState, loading: true }));
        metadataService.getNationalities()
            .then((response) => setNationalities({ loading: false, data: response.data.data }))
            .catch(notifyError)
            .catch(() => setNationalities({ loading: false, data: null }));


        setOriginState((prevState) => ({ ...prevState, loading: true }));
        locationService.getStates(1)
            .then((response) => setOriginState({ loading: false, data: response.data.data }))
            .catch(notifyError)
            .catch(() => setOriginState({ loading: false, data: null }));

    }, []);

    useEffect(() => {
        if (formData.ESTADONATAL) {
            setOriginCity((prevState) => ({ ...prevState, loading: true }));
            locationService.getCities(formData.PAIS, formData.ESTADONATAL)
                .then((response) => setOriginCity({ loading: false, data: response.data.data }))
                .catch(notifyError)
                .catch(() => setOriginCity({ loading: false, data: null }));
        }
    }, [formData.ESTADONATAL]);

    // Manipula o envio do formulário
    const handleSubmit = () => {
        setIsSaving(true);
        recordService.updateRecord(codRecord, formData)
            .then(() => nextStep())
            .catch(notifyError)
            .finally(() => setIsSaving(false));
    };

    useEffect(() => {
        console.log({ formData })
    }, [formData])

    return (
        <form className="d-flex flex-column flex-grow-1" onSubmit={(e) => { e.preventDefault() }}>
            <section className="row">
                <h6>Dados do Aluno</h6>

                <TextInputComponent
                    label="Nome"
                    placeholder={isLoadingRecord ? "Carregando..." : ""}
                    className="col-12 col-md-4"
                    value={formData.NOME || ""}
                    dataKey="NOME"
                    onChange={updateFormData}
                    disabled={isLoadingRecord}
                />

                <SelectInputComponent
                    label="Sexo"
                    placeholder={(genders.loading || isLoadingRecord) ? "Carregando..." : "Sexo"}
                    options={genders.data && genders.data.map((item) => ({ ...item, key: item['CODINTERNO'], label: item['NOME'] }))}
                    value={formData.SEXO || ""}
                    onSelect={(option) => updateFormData("SEXO", option.CODINTERNO)}
                    disabled={!genders.data || isLoadingRecord}
                    className="col-12 col-md-4"
                />

                <SelectInputComponent
                    label="Nacionalidade"
                    placeholder={(nationalities.loading || isLoadingRecord) ? "Carregando..." : "Nacionalidade"}
                    options={nationalities.data && nationalities.data.map((item) => ({ ...item, key: item['CODINTERNO'], label: item['NOME'] }))}
                    value={formData.CODNACIONALIDADE || ""}
                    onSelect={(option) => {
                        updateFormData("CODNACIONALIDADE", option.CODINTERNO)
                        updateFormData("NACIONALIDADE", option.NOME)
                    }}
                    disabled={!nationalities.data || nationalities.loading || isLoadingRecord}
                    className="col-12 col-md-4"
                />

                <SelectInputComponent
                    label="Estado Civil"
                    placeholder={(maritalStatuses.loading || isLoadingRecord) ? "Carregando..." : "Estado Civil"}
                    options={maritalStatuses.data && maritalStatuses.data.map((item) => ({ ...item, key: item['CODINTERNO'], label: item['NOME'] }))}
                    value={formData.ESTADOCIVIL || ""}
                    onSelect={(option) => updateFormData("ESTADOCIVIL", option.CODINTERNO)}
                    disabled={!maritalStatuses.data || maritalStatuses.loading || isLoadingRecord}
                    className="col-12 col-md-4"
                />

                <DateInputComponent
                    label="Data de Nascimento"
                    placeholder={isLoadingRecord ? "Carregando..." : ""}
                    className="col-12 col-md-4"
                    dataKey="DTNASCIMENTO" // Adiciona o dataKey para identificação no componente
                    value={formData.DTNASCIMENTO || ""}
                    onChange={(key, date) => updateFormData(key, date)} // Passa a chave e o valor para a função de atualização
                    disabled={isLoadingRecord}
                />



                <SelectInputComponent
                    label="Estado Natal"
                    placeholder={(originState.loading || isLoadingRecord) ? "Carregando..." : "Estado Natal"}
                    options={originState.data && originState.data.map((item) => ({ ...item, key: item['CODESTADO'], label: item['NOME'] }))}
                    value={formData.ESTADONATAL || ""}
                    onSelect={(option) => {

                        if (formData.ESTADONATAL != option.CODESTADO) {
                            updateFormData("NATURALIDADE", null);
                            updateFormData("CODMUNICIPIONATURALIDADE", null);
                        }

                        updateFormData("ESTADONATAL", option.CODESTADO);
                    }}
                    disabled={!originState.data || originState.loading || isLoadingRecord}
                    className="col-12 col-md-4"
                />

                <SelectInputComponent
                    label="Cidade Natal"
                    placeholder={(originCity.loading || isLoadingRecord) ? "Carregando..." : "Cidade Natal"}
                    options={originCity.data && originCity.data.map((item) => ({ ...item, key: item['NOME'], label: item['NOME'] }))}
                    value={formData.NATURALIDADE || ""}
                    onSelect={(option) => {
                        updateFormData("NATURALIDADE", option.NOME);
                    }}
                    disabled={!originCity.data || originState.loading || isLoadingRecord}
                    className="col-12 col-md-4"
                />


                <TextInputComponent
                    label="Nome do Pai"
                    placeholder={isLoadingRecord ? "Carregando..." : ""}
                    className="col-12 col-md-4"
                    value={formData.NOMEPAI || ""}
                    dataKey="NOMEPAI"
                    onChange={updateFormData}
                    disabled={isLoadingRecord}
                />


                <TextInputComponent
                    label="Nome da Mãe"
                    placeholder={isLoadingRecord ? "Carregando..." : ""}
                    className="col-12 col-md-4"
                    value={formData.NOMEMAE || ""}
                    dataKey="NOMEMAE"
                    onChange={updateFormData}
                    disabled={isLoadingRecord}
                />
            </section>

            <section className="row mt-3">
                <h6>Documentos do Aluno</h6>

                <IdentityInputComponent
                    label="RG"
                    placeholder={isLoadingRecord ? "Carregando..." : ""}
                    className="col-12 col-md-4"
                    dataKey="CARTIDENTIDADE"
                    value={formData.CARTIDENTIDADE || ""}
                    onChange={(key, value) => updateFormData(key, cleanIdentity(value))}
                    disabled={isLoadingRecord}
                />

                <TextInputComponent
                    label="Órgão Expeditor"
                    placeholder={isLoadingRecord ? "Carregando..." : ""}
                    className="col-8 col-md-3"
                    dataKey="ORGEMISSORIDENT"
                    value={formData.ORGEMISSORIDENT || ""}
                    onChange={updateFormData}
                    disabled={isLoadingRecord}
                />

                <SelectInputComponent
                    label="Estado"
                    placeholder={(originState.loading || isLoadingRecord) ? "Carregando..." : "UF"}
                    options={originState.data && originState.data.map((item) => ({ ...item, key: item['CODESTADO'], label: item['CODESTADO'] }))}
                    value={formData.UFCARTIDENT || ""}
                    onSelect={(option) => {
                        updateFormData("UFCARTIDENT", option.CODESTADO);
                    }}
                    disabled={!originState.data || originState.loading || isLoadingRecord}
                    className="col-4 col-md-1"
                />

                <DateInputComponent
                    label="Data de Emissão"
                    placeholder={isLoadingRecord ? "Carregando..." : ""}
                    className="col-12 col-md-4"
                    dataKey="DTEMISSAOIDENT"
                    value={formData.DTEMISSAOIDENT || ""}
                    onChange={updateFormData}
                    disabled={isLoadingRecord}
                />
            </section>

            <div className="flex-grow-1 d-flex justify-content-end align-items-end">
                <PreviousButtonComponent onClick={previousStep} />
                <NextButtonComponent onClick={handleSubmit} loading={isSaving} disabled={!isStepValid} />
            </div>
        </form>
    );
};

export default Step3;
